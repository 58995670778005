export const LanguagesType = {
    // Not found Page
    Not_found_page: 'Not_found_page',
    //Global
    Go_back: 'Go_back',
    // Topbar
    Slogan: 'Slogan',
    // PutPostCode
    Error_PERMISSION_DENIED: 'Error_PERMISSION_DENIED',
    Error_POSITION_UNAVAILABLE: 'Error_POSITION_UNAVAILABLE',
    Error_TIMEOUT: 'Error_TIMEOUT',
    Error_UNKNOWN_ERROR: 'Error_UNKNOWN_ERROR',
    Error_default: 'Error_default',
    Search_your_optic: 'Search_your_optic',
    Description_for_search_optic: 'Description_for_search_optic',
    Alert: 'Alert',
    Placeholder_search_optic: 'Placeholder_search_optic',
    Can_I_Have_Your_Ubication: 'Can_I_Have_Your_Ubication',
    Description_We_Need_Ubication: 'Description_We_Need_Ubication',
    Confirm: 'Confirm',
    Denaied: 'Denaied',
    Cant_Accesses_Your_Ubication: 'Cant_Accesses_Your_Ubication',
    Description_Cant_Accesses_Your_Ubication: 'Description_Cant_Accesses_Your_Ubication',
    Accept: 'Accept',
    Search_GPS: 'Search_GPS',
    Use_My_Location: 'Use_My_Location',
    No_Selection_Location: 'No_Selection_Location',
    Search: 'Search',
    Ofert1: 'Ofert1',
    // Ubication
    Go_to_the_list: 'Go_to_the_list',
    This_is_your_optics: 'This_is_your_optics',
    Description_We_Need_Ubication2: 'Description_We_Need_Ubication2',
    Not_Found_Optics: 'Not_Found_Optics',
    Form_to_contact: 'Form_to_contact',
    Distance: 'Distance',
    Ask_date: 'Ask_date',
    // CardOptic
    Optic_nearest: 'Optic_nearest',
    Of_distance: 'Of_distance',
    See_map: 'See_map',
    // Schedule
    Ofert2: 'Ofert2',
    Choose_optic: 'Choose_optic',
    Direction: 'Direction',
    Selected_day: 'Selected_day',
    Choose_hour: 'Choose_hour',
    Load_more_days: 'Load_more_days',
    No_search_schedules: 'No_search_schedules',
    Change_optic: 'Change_optic',
    See_more: 'See_more',
    Try_another_week: 'Try_another_week',
    // Hours Container
    No_schedule: 'No_schedule',
    // ClientData
    Form_title: 'Form_title',
    Receive_ofert: 'Receive_ofert',
    Your_choose_optic: 'Your_choose_optic',
    Your_choose_date: 'Your_choose_date',
    Name: 'Name',
    Subname: 'Subname',
    Phone: 'Phone',
    Email: 'Email' ,
    Incorrect_field: 'Incorrect_field',
    Next: 'Next',
    // Confirmation
    Error_create_appointment: 'Error_create_appointment',
    Error_update_appointment: 'Error_update_appointment',
    Edit_your_data: 'Edit_your_data',
    Confirm_for_reserve: 'Confirm_for_reserve',
    Change_my_optic: 'Change_my_optic',
    Change_my_date: 'Change_my_date',
    Your_data: 'Your_data',
    Confirm_appointment: 'Confirm_appointment',
    Edit_my_data: 'Edit_my_data',
    // Resume
    Greetings: 'Greetings',
    Of: 'Of',
    Await_you: 'Await_you',
    At: 'At',
    If_put: 'If_put',
    No_24h: 'No_24h',
    Pre_exam: 'Pre_exam',
    Is_easy: 'Is_easy',
    Benefit_appointment_title: 'Benefit_appointment_title',
    Benefit_appointment_1: 'Benefit_appointment_1',
    Benefit_appointment_2: 'Benefit_appointment_2',
    Benefit_appointment_3: 'Benefit_appointment_3',
    Benefit_appointment_4: 'Benefit_appointment_4',
    Free: 'Free',
    Remember: 'Remember',
    Promotion: 'Promotion',
    No_assist: 'No_assist',
    Or: 'Or',
    Start: 'Start',
    Until_the: 'Until_the',
};



export interface TranslateDto {
     // Not found Page
    Not_found_page: string;
    //Global
    Go_back: string;
    // Topbar
    Slogan: string;
    // PutPostCode
    Error_PERMISSION_DENIED: string;
    Error_POSITION_UNAVAILABLE: string;
    Error_TIMEOUT: string;
    Error_UNKNOWN_ERROR: string;
    Error_default: string;
    Search_your_optic: string;
    Description_for_search_optic: string;
    Alert: string;
    Placeholder_search_optic: string;
    Can_I_Have_Your_Ubication: string;
    Description_We_Need_Ubication: string;
    Confirm: string;
    Denaied: string;
    Cant_Accesses_Your_Ubication: string;
    Description_Cant_Accesses_Your_Ubication: string;
    Accept: string;
    Search_GPS: string;
    Use_My_Location: string;
    No_Selection_Location: string;
    Search: string;
    Ofert1: string;
    // Ubication
    Go_to_the_list: string;
    This_is_your_optics: string;
    Description_We_Need_Ubication2: string;
    Not_Found_Optics: string;
    Form_to_contact: string;
    Distance: string;
    Ask_date: string;
    // CardOptic
    Optic_nearest: string;
    Of_distance: string;
    See_map: string;
    // Schedule
    Ofert2: string;
    Choose_optic: string;
    Direction: string;
    Selected_day: string;
    Choose_hour: string;
    Load_more_days: string;
    No_search_schedules: string;
    Change_optic: string;
    See_more: string;
    Try_another_week: string;
    // Hours Container
    No_schedule: string;
    // ClientData
    Form_title: string;
    Receive_ofert: string;
    Your_choose_optic: string;
    Your_choose_date: string;
    Name: string,
    Subname: string,
    Phone: string,
    Email: string,
    Incorrect_field: string,
    Next: string,
    // Confirmation
    Error_create_appointment: string,
    Error_update_appointment: string,
    Edit_your_data: string,
    Confirm_for_reserve: string,
    Change_my_optic: string,
    Change_my_date: string,
    Your_data: string,
    Confirm_appointment: string,
    Edit_my_data: string,
    // Resume
    Greetings: string,
    Of: string,
    Await_you: string,
    At: string,
    If_put: string,
    No_24h: string,
    Pre_exam: string,
    Is_easy: string,
    Benefit_appointment_title: string,
    Benefit_appointment_1: string,
    Benefit_appointment_2: string,
    Benefit_appointment_3: string,
    Benefit_appointment_4: string,
    Free: string,
    Remember: string,
    Promotion: string,
    No_assist: string,
    Or: string,
    Start: string,
    Until_the: string,
    // FAQs
    Question_1: string,
    Answer_1: string,
    Question_2: string,
    Answer_2: string,
    Question_3: string,
    Answer_3: string,
    Question_4: string,
    Answer_4: string,
    Question_5: string,
    Answer_5: string,
};