import { ModuleType } from 'i18next';
import { store } from '../redux/store';

// This file is used to detect the user language
const languageDetector = {
  type: 'languageDetector' as ModuleType,
  async: true, // flags below detection to be async
  detect: (cb: any) => {
      //If the user has a language in the store, use it
      if (store.getState().language) {
        cb(store.getState().language);
        return;
      }
      
      //Get the user language using navigator
      //@ts-ignore
      const language = store.getState().language !== null ? store.getState().language : navigator.language || navigator.userLanguage;
      //get the first part of the language (en-US => en)
      const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
      const allowed_languages = ['es', 'pt', 'en', 'it'];
      
      if(allowed_languages.includes(languageWithoutRegionCode)){
        //Set the language in the store
        console.log("Setting language: ", languageWithoutRegionCode);
        store.dispatch({
          type: 'SET_LANGUAGE',
          payload: languageWithoutRegionCode,
        });
      } else {
        //Set the language in the store
      store.dispatch({
        type: 'SET_LANGUAGE',
        payload: 'es',
      });
      }
      
      

      //Return the language
      cb(languageWithoutRegionCode);
    },
    init: () => {},
    cacheUserLanguage: () => {},
};

export default languageDetector;
