import React, { CSSProperties, useEffect, useState } from "react";
import PropTypes from 'prop-types';

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { setOptica, removeOptica } from "../../redux/actions/optica.action";

import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { removeHorario } from "../../redux/actions/horario.action";

import { TbCalendarTime } from "react-icons/tb";
import { RiMapPinUserFill } from "react-icons/ri";
import { Tracking } from "../../services/tracking.service";
import { LanguagesType } from "../../i18n/translate.dto";
import { useTranslation } from "react-i18next";
import { OpticDTO } from "../../services/DTOs_news/optics/optic.DTO";

const CardOptica = (props: any) => {
    const optic: OpticDTO = props.optic;
    const nearest: boolean = props.isNearest ?? false;
    const hideDistance: boolean = props.hideDistance ?? false;

    const reduxState = useSelector((state: any) => state);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { t } = useTranslation();

    useEffect(() => {
    }, []);

    const selectOptic = () => {
        if(optic.id){
            Tracking.trackOpticaSelecionada(optic.id);
        }
        if (reduxState.optic?.id !== optic.id) {
            dispatch(removeHorario());
        }
        dispatch(setOptica({ ...optic }))
        navigate("/horarios");
    };

    const distance = Math.round((optic.distanceFromCords ? optic.distanceFromCords : 0) * 10) / 10;
    const redeableDistance = distance < 1 ? (distance * 1000 + "m") : (distance + "km");

    return (

        <Card style={nearest ? styles.nearestCard : props.isSelected ? { ...styles.card, backgroundColor: '#F0F1F1' } : { ...styles.card }} outline onClick={selectOptic}>
            {nearest && (
                <CardHeader style={{ backgroundColor: '#F0F1F1', padding: '2em 1em' }}>
                    <Row>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" className="d-flex justify-content-start align-items-start">
                            <span> <RiMapPinUserFill size={"1.3rem"} />{t(LanguagesType.Optic_nearest)}</span>
                        </Col>
                    </Row>
                </CardHeader>
            )}

            <CardBody style={{ paddingBottom: 0 }}>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                        {(optic.distanceFromCords && !hideDistance) &&
                            <Row>
                                <span style={styles.cardSmallText} className="card-distance">{redeableDistance} {t(LanguagesType.Of_distance)}</span>
                            </Row>
                        }
                        <Row>
                            <span style={styles.cardTitleText} className="card-title">{optic.name}</span>
                        </Row>
                        <Row>
                            <span style={styles.cardSmallText} className="card-title">{optic.direction}</span>
                        </Row>
                        <Row style={styles.lastRow}>
                            <Col xs="6" sm="6" md="6" lg="6" xl="6" xxl="6">
                                <span style={{ ...styles.cardSmallText, color: '#EC6245', cursor: 'pointer', width: 'auto' }} className="card-title" onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    props.setClickedOptic(optic);
                                    props.setIsMapMode(true);
                                }}>{t(LanguagesType.See_map)}</span>
                            </Col>
                            <Col xs="12" sm="6" md="6" lg="6" xl="6" xxl="6" className="d-flex justify-content-center align-self-end mt-2 mt-sm-0 align-middle">
                                <button style={styles.button} className="align-self-end">
                                    <TbCalendarTime/> {t(LanguagesType.Ask_date)}
                                </button>
                            </Col>
                        </Row>
                    </Col>
                    {/* Add a > to the left of the card on the center verticaly*/}
                </Row>
            </CardBody>
        </Card>
    );
}

const styles = {
    card: {
        borderRadius: "0px",
        border: "1px solid #8B8B8B",
        borderLeft: "0",
        borderRight: "0",
        borderTop: "0",
        cursor: 'pointer',
    } as CSSProperties,
    selectedCard: {
        borderRadius: "0px",
        border: "1px solid #8B8B8B",
        borderLeft: "0",
        borderRight: "0",
        borderTop: "0",
        cursor: 'pointer',
        backgroundColor: '#F0F1F1',
    } as CSSProperties,
    nearestCard: {
        borderRadius: "5px",
        border: "1px solid #8B8B8B",
        borderLeft: "0",
        borderRight: "0",
        borderTop: "0",
        cursor: 'pointer',
        backgroundColor: '#fbecd8',
        marginBottom: '2em',
    } as CSSProperties,
    cardBody: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cardSmallText: {
        fontSize: '0.8em',
        fontWeight: 400,
    } as CSSProperties,

    cardTitleText: {
        fontSize: '1.2em',
        fontWeight: 700,
    } as CSSProperties,
    buttonBox: {
        height: '100%',
        cursor: 'pointer',
        backgroundColor: '#EC6245',
        color: 'white',
        border: 'none',
        padding: '0.2em 1em',
    },
    button: {
        height: '100%',
        cursor: 'pointer',
        backgroundColor: '#EC6245',
        color: 'white',
        border: 'none',
        padding: '0.5em 1em',
        borderRadius: '5px',

    },
    lastRow: {
        marginBottom: '5%',
    }
};

export default CardOptica;
