import React, { useCallback, useEffect, useState, useTransition } from "react";

import { Alert, Button, Col, Container, Input, Row } from "reactstrap";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/actions/user.action";
import { TbMapSearch } from "react-icons/tb";
import { MdGpsFixed } from "react-icons/md";
import { Tracking } from "../../services/tracking.service";
//Webpack optimization
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { LanguagesType } from "../../i18n/translate.dto";

const PutPostCode = (props: any) => {
    const dispatch = useDispatch();
    const { locationCallback } = props;
    const [error, setError] = useState({ error: false, message: "" });
    const [locationSearchPending, setLocationSearchPending] = useState(false);
    const [gpsIsLoading, setGpsIsLoading] = useState(false);
    const [countryLocationSearch, setCountryLocationSearch] = useState<string | null>(null);

    const { t } = useTranslation();

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
    } = usePlacesService({
        apiKey: "AIzaSyAjBNf0HTsOuINudKthKmKQpumV3NRMjuw",
    });

    const [input, setInput] = useState("");
    const reduxState = useSelector((state: any) => state);

    let [gpsResult, setGpsResult] = useState<any>(null);
    const [placePredictions_local, setPlacePredictions_local] = useState<google.maps.places.AutocompletePrediction[]>([]);


    useEffect(() => {
        const hasPermissions = navigator.permissions?.query({ name: 'geolocation' });
        if (hasPermissions) {
            hasPermissions.then((res) => {
                setGpsResult(res);
            });
        }
    }, []);

    useEffect(() => {
        if (placePredictions?.length > 0) {
            //Check if is not the same as the previous one
            if (placePredictions_local?.length > 0) {
                if (placePredictions[0].place_id !== placePredictions_local[0].place_id) {
                    setPlacePredictions_local(placePredictions);
                }
            } else {
                setPlacePredictions_local(placePredictions);
            }
        }
    }, [placePredictions]);


    const handlegeolocationError = (error: any) => {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                setError({ error: true, message: t(LanguagesType.Error_PERMISSION_DENIED) });
                break;
            case error.POSITION_UNAVAILABLE:
                setError({ error: true, message: t(LanguagesType.Error_POSITION_UNAVAILABLE) });
                break;
            case error.TIMEOUT:
                setError({ error: true, message: t(LanguagesType.Error_TIMEOUT) });
                break;
            case error.UNKNOWN_ERROR:
                setError({ error: true, message: t(LanguagesType.Error_UNKNOWN_ERROR) });
                break;
            default:
                setError({ error: true, message: t(LanguagesType.Error_default) });
                break;
        }
    };

    const handleClick = (placeId: string) => {
        placesService?.getDetails(
            {
                placeId: placeId,
            },
            (placeDetails) => {
                const lat = placeDetails?.geometry?.location?.lat();
                const lng = placeDetails?.geometry?.location?.lng();
                setInput(placeDetails?.formatted_address || "");

                //Set the user post code to the redux store
                let post_code = placeDetails?.address_components?.find((item: any) => item.types.includes("postal_code"))?.long_name;
                if (post_code) {
                    post_code = post_code.replace(/\s/g, "");
                }
                dispatch(setUser({ ...reduxState.user, cords: { lat, lng }, searchedAddress: placeDetails?.formatted_address || "", postCode: post_code || "00000" }));

                //reduxState.user.cords = { lat, lng }; // set the cords to the redux state so in the next dispatch the cords will be also included
                setError({ error: false, message: "" });
                Tracking.trackBuscarOpticas();
                setLocationSearchPending(false);
                locationCallback(lat, lng);
            }
        );
        //Hide the list of predictions
        getPlacePredictions({ input: "" });
        setPlacePredictions_local([]);
    };

    const handleClickCallback = useCallback(handleClick, [placesService, reduxState]);

    //update reduxState on recived changes
    useEffect(() => {
        //Check if it's diferent from the redux state
        if (reduxState.user?.searchedAddress !== input) {
            //Set the input to the redux state
            setInput(reduxState.user?.searchedAddress || "");
            getPlacePredictions({ input: "" });
            setPlacePredictions_local([]);
        }
    }, [reduxState.user?.searchedAddress]);


    return (
        <Container style={{ backgroundColor: '#F0F1F1', paddingTop: "3%", marginBottom: "3%", borderRadius: "5px", zIndex: 5 }} >
            <Col
                xl={12}
                lg={10}
                md={10}
                sm={11}
                xs={11}
                style={{ margin: 'auto' }}
            >
                <Row className="mt-3 mb-2" >
                    <div className="text-center" style={{ fontWeight: 'bold', fontSize: '1.25em' }}>
                        {t(LanguagesType.Search_your_optic)}
                    </div>
                    <div>
                        <div className="text-center" style={{ fontSize: '0.8em' }}>
                            {t(LanguagesType.Description_for_search_optic)}
                        </div>
                    </div>
                </Row>
                <Row
                    xl={10}
                    lg={10}
                    md={10}
                    sm={10}
                    xs={10}
                    className="mt-3 mb-2"
                    style={{ margin: 'auto' }}
                >
                    {error.error && (
                        <Alert color="danger" onClose={() => setError({ error: false, message: "" })} toggle={() => { setError({ error: false, message: "" }) }}>
                            <h4 className="alert-heading">{t(LanguagesType.Alert)}</h4>
                            <p>
                                {error.message}
                            </p>
                        </Alert>
                    )}

                    <input
                        onChange={(e) => {
                            setInput(e.target.value);
                            setLocationSearchPending(true);
                            getPlacePredictions({ input: e.target.value, types: ['geocode'], componentRestrictions: { country: countryLocationSearch !== null ? countryLocationSearch : reduxState.country } });

                        }}
                        value={input}
                        type="text"
                        name="postCode"
                        placeholder={(t(LanguagesType.Placeholder_search_optic)).toString()}
                        //Fill width of parent
                        style={{ width: '100%', height: '6vh', borderRadius: '0.2em', border: '0px', borderBottom: '1px solid #000000' }}
                        className="w-100"
                    />
                    {placePredictions_local.length > 0 && (

                        <ul style={{ width: '100%', borderBottomRightRadius: '0.5em', borderBottomLeftRadius: '0.5em', background: 'white', overflowY: 'auto', maxHeight: '150px', zIndex: 4 }}>
                            {placePredictions_local.map((prediction, index) => (

                                <div key={index} onClick={() => {
                                    handleClickCallback(prediction.place_id);
                                }} style={{ borderBottom: '1px solid #000000', width: "100%", fontSize: "1.2rem", cursor: 'pointer' }}>{prediction.description}</div>
                            ))}
                        </ul>
                    )}

                    {(!locationSearchPending && navigator.geolocation && (gpsResult?.state === 'granted' || gpsResult?.state === 'prompt')) ? (
                        // Get cords from user using geolocation if the user clicks on the button

                        <Button
                            onClick={
                                () => {
                                    setGpsIsLoading(true);

                                    if (gpsResult.state === 'granted') {
                                        //If the user has already granted permission, get the location
                                        navigator.geolocation.getCurrentPosition(function (position) {
                                            // console.log("position", position);
                                            const lat = position.coords.latitude;
                                            const lng = position.coords.longitude;
                                            const geocoder = new google.maps.Geocoder();
                                            geocoder
                                                .geocode({ location: { lat, lng } })
                                                .then((response) => {
                                                    if (response.results.length > 0) {
                                                        const shortName: string = response.results[response.results.length - 1].address_components[0].short_name;
                                                        setCountryLocationSearch(shortName);
                                                    }
                                                });
                                            //getPlacePredictions({ input: (String(lat)+","+String(lng)), types: ['geocode'], componentRestrictions: { country: 'es' } });
                                            dispatch(setUser({ ...reduxState.user, cords: { lat, lng } }));
                                            Tracking.trackBuscarOpticas();
                                            setLocationSearchPending(false);
                                            locationCallback(lat, lng);
                                            setGpsIsLoading(false);
                                            //Get the address from the coordinates

                                        }, handlegeolocationError);
                                    } else if (gpsResult.state === 'prompt') {
                                        //Show a sweet alert to ask for permission
                                        Swal?.fire({
                                            title: (t(LanguagesType.Can_I_Have_Your_Ubication)).toString(),
                                            text: (t(LanguagesType.Description_We_Need_Ubication)).toString(),
                                            icon: 'question',
                                            showCancelButton: true,
                                            confirmButtonColor: '#3085d6',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText: (t(LanguagesType.Confirm)).toString(),
                                            cancelButtonText: (t(LanguagesType.Denaied)).toString(),
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                //If the user accepts, get the location
                                                navigator.geolocation.getCurrentPosition(function (position) {
                                                    // console.log("position", position);
                                                    const lat = position.coords.latitude;
                                                    const lng = position.coords.longitude;
                                                    getPlacePredictions({ input: ` ${lat},${lng}`, types: ['geocode'] });
                                                    locationCallback(lat, lng);
                                                    dispatch(setUser({ ...reduxState.user, cords: { lat, lng } }));
                                                    Tracking.trackBuscarOpticas();
                                                    setLocationSearchPending(false);
                                                    setGpsIsLoading(false);
                                                    //Get the address from the coordinates

                                                }, handlegeolocationError);
                                            } else {
                                                setGpsIsLoading(false);
                                            }

                                        })
                                    } else if (gpsResult.state === 'denied') {
                                        //If the user has denied permission, show a sweet alert to explain why we need it
                                        Swal?.fire({
                                            title: (t(LanguagesType.Can_I_Have_Your_Ubication)).toString(),
                                            text: (t(LanguagesType.Description_We_Need_Ubication)).toString(),
                                            icon: 'error',
                                            confirmButtonColor: '#3085d6',
                                            confirmButtonText: (t(LanguagesType.Accept)).toString(),
                                        })
                                    }
                                    gpsResult.onchange = function () {
                                        // console.log(gpsResult.state);
                                        if (gpsResult.state === 'denied') {
                                            Swal?.fire({
                                                title: (t(LanguagesType.Can_I_Have_Your_Ubication)).toString(),
                                                text: (t(LanguagesType.Description_We_Need_Ubication)).toString(),
                                                icon: 'error',
                                                confirmButtonColor: '#3085d6',
                                                confirmButtonText: (t(LanguagesType.Accept)).toString(),
                                            })
                                        }
                                    }

                                }
                            }

                            style={{ width: '90%', borderRadius: '0.2em', border: '0px', borderBottom: '0px solid #000000', fontWeight: 'bold', }}
                            className="mx-auto align-center mt-4"
                        >
                            <MdGpsFixed /> {gpsIsLoading ? (t(LanguagesType.Search_GPS)) : (t(LanguagesType.Use_My_Location))}
                        </Button>


                    ) : (
                        <>
                            {(locationSearchPending || (input === "")) && (
                                <Button
                                    onClick={() => {
                                        const place = placePredictions_local[0];
                                        if (place) {
                                            // console.log("place", place);
                                            handleClick(place.place_id);
                                        } else if (reduxState.user?.cords?.lat) {
                                            // console.log("Searching by user cords");
                                            locationCallback(reduxState.user?.cords?.lat, reduxState.user?.cords?.lng);
                                        } else {
                                            // console.log("No place selected");
                                            setError({ error: true, message: t(LanguagesType.No_Selection_Location) });
                                            return;
                                        }
                                        //If we'r here there is no error
                                        setError({ error: false, message: "" });
                                    }}
                                    style={{ width: '100%', height: '2.3em', borderRadius: '0.5em', marginTop: '1em', fontWeight: 'bold', fontSize: '1.1em', ...(locationSearchPending ? { backgroundColor: '#FF4D00' } : {}) }}
                                    className="w-75 mx-auto mt-3"
                                >
                                    <TbMapSearch /> {t(LanguagesType.Search)}
                                </Button>
                            )}
                        </>
                    )}


                </Row>
                <Row className="mt-3">
                    <div className="text-center" style={{ color: '#FF4D00', fontWeight: 'bold', fontSize: '0.9em' }}>{t(LanguagesType.Ofert1)}</div>
                </Row>
            </Col>
        </Container>
    );
}

export default PutPostCode;
