import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, useStore } from "react-redux";
import { RoughNotation } from "react-rough-notation";
import { Row } from "reactstrap";
import { LanguagesType } from "../../i18n/translate.dto";
import { setLanguage as setLanguageRedux } from "../../redux/actions/language.actions";

import FlipoLogo from './../../assets/FLIPO_Logo.svg';



const TopBarLogo = () => {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState<boolean>(false);
    const dispatch = useDispatch();

    const reduxState = useSelector((state: any) => state);


    const changeLanguage = () => {
        if (reduxState.language === 'es') {
            setLanguage(true);
            dispatch(setLanguageRedux('pt'))
        } else {
            setLanguage(false);
            dispatch(setLanguageRedux('es'))
        }
    }
    return (
        <Row style={{ height: '14vh'}}>
            <img style={{ height: '7vh' }} src={FlipoLogo} alt="" />
            <div style={{height: '5vh'}}>
                {/* <button style={{backgroundColor: 'white', border: 0, cursor: 'pointer', fontWeight: '500'}} onClick={changeLanguage}>{reduxState.language}</button> */}
                <RoughNotation type="underline" show={true} customElement={"div"}>
                    <div className="text-center">
                    <span style={{fontWeight: 'bold', textAlign:'center', fontFamily: 'Handlee', fontStyle: 'italic', fontSize:'1.2em', width:"100wh"}} className="mx-auto">{t(LanguagesType.Slogan)}</span>
                    </div>
                </RoughNotation>
            </div>
            <div className="mb1"></div>
        </Row>
    );
};

export default TopBarLogo;