import {
    combineReducers,
    createStore
} from 'redux';

import storage from 'redux-persist/lib/storage';
import {
    persistReducer
} from 'redux-persist';

import opticaReducer from './reducers/optica.reducer';
import horarioReducer from './reducers/horario.reducer';
import userReducer from './reducers/user.reducer';
import leadReducer from './reducers/lead.reducer';
import appointmentReducer from './reducers/appointment.reducer';
import editModeReducer from './reducers/editMode.reducer';
import languageReducer from './reducers/language.reducer';
import countryReducer from './reducers/country.reducer';

const persistConfig = {
    key: 'root',
    storage,
}

export const combinedReducers = combineReducers({
    optic: opticaReducer,
    schedule: horarioReducer,
    user: userReducer,
    idLead: leadReducer,
    appointment: appointmentReducer,
    isEditMode: editModeReducer,
    language: languageReducer,
    country: countryReducer
});

const persistedReducer = persistReducer(persistConfig, combinedReducers)

let store = createStore(persistedReducer);

export {
    store
};